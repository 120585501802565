<template>
  <div class="main-header">
    <div class="header-left" style="width: 240px">
<!--      <img class="header-logo" src="../../../assets/logo.png" alt="">-->
<!--      <div class="filter">-->
<!--        <select name="province" v-model="where.province_id" @change="areaChange(2)">-->
<!--          <option value="" >全国</option>-->
<!--          <option v-for="(p, ind) in provinceList" :key="ind" :value="p.province_id">{{p.gov_name}}</option>-->
<!--        </select>-->
<!--        <select name="city" v-if="cityList.length > 0" v-model="where.city_id" @change="areaChange(3)">-->
<!--          <option value="">全部</option>-->
<!--          <option v-for="(c, ind) in cityList" :key="ind" :value="c.city_id">{{c.gov_name}}</option>-->
<!--        </select>-->
<!--        <select name="county" v-if="countyList.length > 0" v-model="where.county_id" @change="areaChange(4)">-->
<!--          <option value="">全部</option>-->
<!--          <option v-for="(co, ind) in countyList" :key="ind" :value="co.county_id">{{co.gov_name}}</option>-->
<!--        </select>-->
<!--        <select name="qr" v-if="qrList.length > 0" v-model="where.qr" @change="areaChange()">-->
<!--          <option value="">全部</option>-->
<!--          <option v-for="(q, ind) in qrList" :key="ind" :value="q.id">{{q.name}}</option>-->
<!--        </select>-->
<!--      </div>-->
      <dv-border-box-8 class="time-border" v-show="timeStr">{{timeStr}}</dv-border-box-8>
    </div>
    <div class="header-center">抚远市质量基础设施一站式服务需求统计</div>
    <div class="header-right">
      <img class="full-image" v-if="!isFull" src="../../../static/image/fullscreen.png" alt="" @click="screen(true)">
      <img class="full-image" v-else src="../../../static/image/fullscreen-exit.png" alt="" @click="screen(false)">
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  name: "top",
  data() {
    return {
      isFull: false,
      timeId: null,
      timeStr: '',
      provinceList: [],
      cityList: [],
      countyList: [],
      qrList: [],
      level: 1,
      where: {
        province_id: '',
        city_id: '',
        county_id: '',
        qr: ''
      }
    }
  },
  created() {
    this.autoSetTime();
    this.getArea();
  },
  beforeDestroy() {
    if (this.timeId) {
      clearInterval(this.timeId);
    }
  },
  methods: {
    screen(boo) {
      if (!screenfull.isEnabled) { // 如果不允许进入全屏，发出不允许提示
        alert('不支持全屏')
        return false
      }
      this.isFull = boo;
      screenfull.toggle()
    },
    areaChange(level) {
      if (level) {
        this.level = level;
        if (this.level === 2) {
          this.$set(this.where, 'city_id', '')
          this.$set(this.where, 'county_id', '')
          this.$set(this.where, 'qr', '')
          this.cityList = [];
          this.countyList = [];
          this.qrList = [];
        }
        if (this.level === 3) {
          this.$set(this.where, 'county_id', '')
          this.$set(this.where, 'qr', '')
          this.countyList = [];
          this.qrList = [];
        }
        if (this.level === 4) {
          this.qrList = [];
          this.$set(this.where, 'qr', '')
        }

        this.$emit('areaChange', this.where);
        this.getArea()
      }
    },
    getArea() {
      this.$ajax.get('/statistics/demand_company_city/getArea', {params: {level: this.level, ...this.where}}).then(res =>{
        if (this.level === 1) {
          this.provinceList = res;
        }
        if (this.level === 2) {
          this.cityList = res;
        }
        if (this.level === 3) {
          this.countyList = res;
        }
        if (this.level === 4) {
          this.qrList = res;
        }
      })
    },
    setTime() {
      let d = new Date();
      let m = d.getMonth() + 1;
      let dd = d.getDate();
      let h = d.getHours();
      let mm = d.getMinutes();
      let s = d.getSeconds();
      m = m > 9 ? m : '0' + m;
      dd = dd > 9 ? dd : '0' + dd;
      h = h > 9 ? h : '0' + h;
      mm = mm > 9 ? mm : '0' + mm;
      s = s > 9 ? s : '0' + s;

      this.timeStr = d.getFullYear() + '年' + m + '月' + dd + '日 ' + h + ':' + mm + ':' + s;
    },
    autoSetTime() {
      if (this.timeId) {
        clearInterval(this.timeId);
      }
      this.timeId = setInterval(() => {
        this.setTime()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-header {
  padding: 0 30px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.header {
  &-left {
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 0;
    position: relative;
  }
  &-logo {
    height: 44px;
  }
  &-center {
    font-size: 30px;
    text-align: center;
  }
  &-right {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 240px;
    height: 44px;
    position: relative;
  }
}
.time-border {
  line-height: 44px;
  text-align: center;
  font-size: 18px;
}
.filter {
  max-width: 300px;
}
select {
  height: 20px;
  background: transparent;
  color: #fff;
}
option {
  background: #000928;
}


.full-image {
  width: 40px;
  height: 40px;
}
</style>